import React, { useState, useEffect } from "react";
import config from "../../../../config/config.json";
import { apiCall } from "../../../../_services/apiCall";
import Excel from "./excel";

export default function ParentExcel({ allAnswers, allQuestions }) {
  const sourceIds = Array.from(new Set(allAnswers.map(item => item.sourceId)));
  const [sourceLabels, setUserLabels] = useState({});
  const [selectedSourceId, setSelectedSourceId] = useState(sourceIds.length > 1 ? sourceIds[1] : null);
  const [aggregatedData, setAggregatedData] = useState([]);

  const [selectedModuleName, setSelectedModuleName] = useState("ALL");
  const moduleNames = Array.from(new Set(allQuestions.map(item => item.moduleName)));

  const getLocations = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
        {},
        {},
        "GET"
      );

      if (isSuccess && data?.data) {
        const labels = data.data.reduce((acc, item) => {
          if (item.id) {
            acc[item.id] = item.unitCode;
          }
          return acc;
        }, {});
        setUserLabels(labels);
      } else {
        console.warn("getSource API call unsuccessful or returned no data");
      }
    } catch (error) {
      console.error("Error fetching source labels:", error);
    }
  };

  const computeAggregatedData = () => {
    console.log("Aggregating data");

    const invalidValues = new Set([
      "nil",
      "nill",
      "na",
      "not applicable",
      "notapplicable",
      "not-applicable",
    ]);

    const aggregated = allAnswers.reduce((acc, item) => {
      if (!item.answer) {
        return acc;
      }

      try {
        let parsedAnswer = JSON.parse(item.answer);

        if (item.questionType === "tabular_question") {
          // Ensure `parsedAnswer` is an array
          if (!Array.isArray(parsedAnswer)) {
            console.warn("Parsed answer for tabular question is not an array:", parsedAnswer);
            return acc;
          }

          // Check if this question already exists in accumulator
          const existing = acc.find((a) => a.questionId === item.questionId);

          // Async function to handle the question details and aggregation
          (async function handleTabularQuestion() {
            try {

              if (existing) {
                // Aggregate values at the same index
                let existingAnswer;
                try {
                  existingAnswer = JSON.parse(existing.answer[0]);
                } catch (error) {
                  console.error("Failed to parse existing.answer[0]:", existing.answer[0]);
                  existingAnswer = null; // Or handle the error value appropriately
                }

                console.log(`Existing answer for question ID ${item.questionId}: ${existingAnswer}`);
                parsedAnswer.forEach((row, rowIndex) => {
                  row.forEach((value, colIndex) => {
                    const numValue = parseFloat(value);
                    if (!isNaN(numValue)) {
                      existingAnswer[rowIndex] = existingAnswer[rowIndex] || [];
                      existingAnswer[rowIndex][colIndex] =
                        (existingAnswer[rowIndex][colIndex] || 0) + numValue;
                    }
                  });
                });
                existing.answer = JSON.stringify(existingAnswer);
              } else {
                // Add a new entry if it doesn't already exist
                acc.push({
                  ...item
                });
              }
            } catch (error) {
              console.error(`Error fetching question ID ${item.questionId}:`, error);
            }
          })();
        }
        else if (item.questionType === "quantitative_trends") {
          // Handle quantitative_trends question type
          const { questionId, readingValue = 0, fromDate, toDate, ...rest } = parsedAnswer;

          if (!questionId) {
            console.warn("Missing questionId in parsedAnswer:", parsedAnswer);
            return acc;
          }

          const existing = acc.find((a) => {
            const existingParsedAnswer = JSON.parse(a.answer);
            if (!existingParsedAnswer)
              return;
            else {
              return (
                existingParsedAnswer.questionId === questionId &&
                existingParsedAnswer.fromDate === fromDate &&
                existingParsedAnswer.toDate === toDate
              );
            }

          });

          if (existing) {
            const existingParsedAnswer = JSON.parse(existing.answer);
            const existingValNum = Number(existingParsedAnswer.readingValue);
            const readingValueNum = Number(readingValue);

            if (!isNaN(existingValNum)) {
              if (!isNaN(readingValueNum)) {
                existingParsedAnswer.readingValue = existingValNum + readingValueNum;
              } else if (!isNaN(parseFloat(readingValue))) {
                existingParsedAnswer.readingValue = existingValNum + parseFloat(readingValue);
              }
            }

            existing.answer = JSON.stringify(existingParsedAnswer);
          } else {
            acc.push({
              ...item,
              answer: JSON.stringify({
                questionId,
                readingValue,
                fromDate,
                toDate,
                ...rest,
              }),
            });
          }
        }
        else if (item.questionType === "yes_no") {
          // Handle yes_no question type
          const { questionId, fromDate, toDate, notApplicable, answer } = item;

          if (!questionId) {
            console.warn("Missing questionId in yes_no question:", item);
            return acc;
          }
          const parsedAnswer = JSON.parse(answer);

          console.log("Parsed answer from yes no question" + parsedAnswer)

          // Extract yes or no
          const extractedAnswer = parsedAnswer && parsedAnswer.answer && parsedAnswer.answer.trim().toLowerCase() === "yes" ? "yes" : "no";

          // Check if this question already exists in the accumulator
          const existing = acc.find((a) => {
            const existingParsedAnswer = JSON.parse(a.answer || "{}");
            return (
              existingParsedAnswer.questionId === questionId &&
              existingParsedAnswer.fromDate === fromDate &&
              existingParsedAnswer.toDate === toDate
            );
          });

          if (existing) {
            let existingParsedAnswer;
            try {
              existingParsedAnswer = JSON.parse(existing.answer || "{}");
            } catch (error) {
              existingParsedAnswer = { answerCounts: { Yes: 0, No: 0 } };
            }

            const existingAnswerCounts = existingParsedAnswer.answerCounts || { Yes: 0, No: 0 };

            if (typeof extractedAnswer === "string" && extractedAnswer.toLowerCase() === "yes") {
              existingAnswerCounts.Yes += 1;
            } else if (typeof extractedAnswer === "string" && extractedAnswer.toLowerCase() === "no") {
              existingAnswerCounts.No += 1;
            }

            existingParsedAnswer.notApplicable = existingParsedAnswer.notApplicable || notApplicable;
            existingParsedAnswer.answerCounts = existingAnswerCounts;
            existingParsedAnswer.answer = `No (${existingAnswerCounts.No}), Yes (${existingAnswerCounts.Yes})`;

            existing.answer = JSON.stringify(existingParsedAnswer);
          } else {
            const initialCounts = { Yes: 0, No: 0 };
            if (typeof extractedAnswer === "string" && extractedAnswer.toLowerCase() === "yes") {
              initialCounts.Yes = 1;
            } else if (typeof extractedAnswer === "string" && extractedAnswer.toLowerCase() === "no") {
              initialCounts.No = 1;
            }

            existing = {
              notApplicable,
              answer: JSON.stringify({
                answerCounts: initialCounts,
                answer: `No (${initialCounts.No}), Yes (${initialCounts.Yes})`,
              }),
            };
          }


        }

      } catch (error) {
        console.error("Error parsing answer:", item.answer, error);
      }

      return acc;
    }, []);

    console.log("Aggregated data:", aggregated);
    setAggregatedData(aggregated);
  };




  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    if (selectedSourceId === "ALL") {
      computeAggregatedData();
    }
  }, [selectedSourceId, selectedModuleName]);

  // Filter data based on selected source and module name
  // Filter and group data based on selected source and module name
  const filteredData = allAnswers
    .filter((item) => {
      // Find the corresponding question for the current item
      const currentQuestion = allQuestions?.find((question) => question.questionId === item.questionId);

      // Skip the item if no matching question is found
      if (!currentQuestion) {
        console.warn("No matching question found for item:", item);
        return false;
      }

      const matchesSourceId = selectedSourceId === "ALL" || item.sourceId === selectedSourceId;
      const matchesModuleName = selectedModuleName === "ALL" || currentQuestion.moduleName === selectedModuleName;

      // Attach `moduleName` for sorting purposes
      if (matchesSourceId && matchesModuleName) {
        item.moduleName = currentQuestion.moduleName; // Attach `moduleName` to the item
        return true;
      }
      return false;
    })
    .sort((a, b) => {
      // Sort by `moduleName` only if `selectedModuleName` is "ALL"
      console.log("selectedModuleName:  " + selectedModuleName);
      if (selectedModuleName === "ALL") {
        const moduleA = a.moduleName || "";
        const moduleB = b.moduleName || "";
        return moduleA.localeCompare(moduleB);
      }
      return 0; // No sorting needed if `selectedModuleName` is not "ALL"
    });

  return (
    <div>
      <label style={{ marginRight: "8px" }}>Location:</label>
      <select
        value={selectedSourceId !== null ? selectedSourceId : ""}
        onChange={(e) =>
          setSelectedSourceId(
            e.target.value ? (e.target.value === "ALL" ? "ALL" : parseInt(e.target.value)) : null
          )
        }
      >
        <option value="ALL">ALL</option>
        {sourceIds.map((id) => (
          <option key={id} value={id}>
            {sourceLabels[id] || `User ${id}`}
          </option>
        ))}

      </select>

      <label style={{ marginLeft: "16px", marginRight: "8px" }}>Module:</label>
      <select
        value={selectedModuleName !== null ? selectedModuleName : ""}
        onChange={(e) =>
          setSelectedModuleName(e.target.value ? (e.target.value === "ALL" ? "ALL" : e.target.value) : null)
        }
      >
        <option value="ALL">ALL</option>
        {moduleNames.map((name, index) => (
          <option key={index} value={name}>
            {name}
          </option>
        ))}
      </select>

      <div style={{ marginTop: "16px" }}>
        <Excel data={filteredData} allQuestions={allQuestions} />
      </div>
    </div>
  );
}
