import React, { useEffect } from "react";
import { Form, Row, Col, Image, Modal, Button, Table } from "react-bootstrap";
import FrequencyComponent from "./FrequencyComponent";
import QualitativeComponent from "./QuestionsComponent/QualitativeComponent";
import TabularComponent from "./QuestionsComponent/TabularComponent";
import YesNoComponent from "./QuestionsComponent/YesNoComponent";
import TrendsComponent from "./QuestionsComponent/TrendsComponent";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";

import swal from "sweetalert";
import StatusWithTooltip from "./StatusWithToolTip";

const MainAccordComponent = ({
  getAuditListing,
  apiData,
  // getReportingQuestions,
  currentUserId,
  userData,
  menu,
  assignedTo,
  savedAnswers,
  sourceData,
  financeObject,
  applicable,
  activeIndex,
  item,
  index,
  handleAccordionClick,
  getReportingQuestionAnswer,
  startingMonth,
  handlePeriodSelect,
  selectedPeriod,
  financialYear,
  startDate,
  setStartDate,
  setApplicable,
  units,
}) => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "20px",
      width: "100%",
    },
    text: {
      color: "rgba(0, 0, 0, 0.70)",
      fontSize: "12px",
      fontFamily: "Open Sans",
      fontWeight: 400,
    },
    uploadDiv: {
      width: "100%",
      backgroundColor: "#3F88A5",
      borderRadius: "10px",
      height: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      color: "white",
      position: "relative",
      border: "2px dashed #3F88A5",
      transition: "background-color 0.3s ease",
    },
    uploadDivHover: {
      backgroundColor: "#30707E", // Darker blue on hover
    },
    icon: {
      position: "absolute",
      right: "10px",
      fontSize: "10px",
      color: "white",
    },
    fileInput: {
      display: "none",
    },
    formGroup: {
      marginBottom: "15px",
    },
  };
  const [sourceName, setSourceName] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  const [mappedUserData, setMappedUserData] = useState([]);
  const [sourceChange, setSourceChange] = useState(null);
  const [showData, setShowData] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAssignModalOpen, setAssignIsModalOpen] = useState(false);
  const [details, setDetails] = useState([]);

  const [answer, setAnswer] = useState({
    financialYearId: financeObject,
    questionId: item?.questionId,
    questionTitle: item?.title,
    readingValue: "",
    sourceId: null,
    fromDate: "",
    moduleId: item?.moduleId,
    toDate: "",
    notApplicable: null,
    answer: "",
    proofDocument: [],
    note: [[]],
    questionType: item?.questionType,
    frequency: item?.frequency,
  });

  const [auditorObjectt, setAuditorObject] = useState();
  const [selectedSource, setSelectedSource] = useState("");
  const [auditorRemark, setAuditorRemark] = useState(null);
  const [meterList, setMeterList] = useState([]);

  const [ansId, setAnsId] = useState();
  const [subMenuId, setSubMenuId] = useState(null);
  const [selectedSubLocation, setSelectedSubLocation] = useState(null);
  const [selectedSubLocationId, setSelectedSubLocationId] = useState(null);

  const handleSubLocationSelect = (eventKey) => {
    const selectedItem = meterList[0].subLocation.find(
      (item) => item.id == eventKey
    );
    if (selectedItem) {
      setSelectedSubLocation(selectedItem.subLocation);
      setSelectedSubLocationId(selectedItem.id);
    }
  };
  const unit =
    units &&
    units.length &&
    units.find((items) => items?.catagoryId == item?.categoryId);


    const handleSourceSelect = (mainItem, subItem = null) => {
      if (!mainItem) return;
    
      // Construct the selected value
      const selectedValue = subItem
        ? `${mainItem?.unitCode || mainItem?.location?.area} - ${subItem?.subLocation}`
        : mainItem?.unitCode || mainItem?.location?.area;
    
      // Update state with the selected source
      setSelectedSource(selectedValue);
      setSourceChange(mainItem.id); // Update source change
    if(subItem){
      setSubMenuId(subItem.id)
    }
      // Update the answer state with the selected item's id
      setAnswer((prevAnswer) => ({
        ...prevAnswer,
        sourceId:mainItem.id, // Use subItem ID if available
      }));
    
      // setShowDropdown(false); // Close the dropdown after selection
    };
  const handleShowAssign = (item, details) => {
    setAssignIsModalOpen(true);
    setDetails(details);
  };
  const [showSubMenu, setShowSubMenu] = useState(null);

  const handleMouseEnter = (id) => setShowSubMenu(id);
  const handleMouseLeave = (id) => {
    // Delay closing to allow selection in the submenu
    setTimeout(() => {
      if (showSubMenu === id) setShowSubMenu(null);
    }, 300);
  };
  useEffect(() => {
    if (meterList.length === 1 && meterList[0]?.subLocation?.length > 0) {
      setSelectedSubLocationId(meterList[0]?.subLocation[0].id);
    }
  }, [meterList]);


  useEffect(() => {
    if (sourceData) {
      const locationArray = sourceData?.reverse().map((item) => ({
        id: item.id,
        location: `${item?.location?.area}, ${item?.location?.city}, ${item?.location?.state}, ${item?.location?.country}, ${item?.location?.zipCode}`,
        unitCode: item?.unitCode,
        subLocation: item?.subLocation,
      }));
      if (locationArray && locationArray.length) {
        setMeterList(locationArray);
        setSelectedSource(
          locationArray[0]?.unitCode || locationArray[0]?.location
        );
        setSourceChange(locationArray[0]?.id);
        setAnswer((prevAnswer) => ({
          ...prevAnswer,
          sourceId: locationArray[0]?.id,
        }));
      }
    }
  }, [sourceData]);

  useEffect(() => {
    const matchingAuditorObjects =
      item?.matchingAuditors &&
      item?.matchingAuditors.filter((auditor) => auditor.answerId === ansId);

    setAuditorObject(matchingAuditorObjects);
    setAuditorRemark(
      matchingAuditorObjects ? matchingAuditorObjects[0]?.remark : null
    );
    if (
      userData &&
      matchingAuditorObjects &&
      matchingAuditorObjects[0]?.remark
    ) {
      const filteredUserData = userData.filter((user) =>
        matchingAuditorObjects[0]?.remark.some(
          (auditor) => auditor.id.toString() === user.id.toString()
        )
      );
      setMappedUserData(filteredUserData);
    }
  }, [ansId, financeObject, item]);
  const [letestAnswer, setLetestAnswer] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [singleItem, setSingleItem] = useState(null);
  const [auditorId, setAuditorId] = useState();
  const [remark, setRemark] = useState("");
  const [note, setNote] = useState([[]]);
  const [auditButton, setAuditButton] = useState(false);
  const [assignedToDetails, setAssignedToDetails] = useState(null);
  const getReportingAnswer = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getReportingAnswer`,
      {},
      { financialYearId: financeObject, questionId: singleItem.questionId },
      "GET"
    );
    if (isSuccess) {
      setLetestAnswer(data?.answers);
    }
  };

  useEffect(() => {
    if (singleItem && financeObject) getReportingAnswer();
  }, [singleItem?.questionId]);

  useEffect(() => {
    const assignedToDetailss = assignedTo?.find((data) =>
      menu === "audit"
        ? data.questionId === item.question.questionId
        : data.questionId === item.questionId
    );
    setAssignedToDetails(assignedToDetailss);
  }, [assignedTo, item]);

  useEffect(() => {
    if (item) {
      if (menu === "audit") {
        setAuditorId(item.auditorId.auditerId);
      } else {
        setAuditorId();
      }
    }
  }, [item]);
  const [selectedValue, setSelectedValue] = useState();

  const initializeAnswer = () => {
    if (menu === "audit") {
      if (
        item &&
        item.question.questionId &&
        letestAnswer &&
        letestAnswer.length
      ) {
        // const matchedAnswer = savedAnswers.find(
        //   (savedAnswer) => savedAnswer.questionId == item.questionId
        // );
        let matchedAnswer;
        if (item?.question?.frequency == "CUSTOM") {
          matchedAnswer = letestAnswer.find(
            (savedAnswer) =>
              savedAnswer.questionId == item.question.questionId &&
              savedAnswer.toDate == toDate &&
              savedAnswer.fromDate == fromDate &&
              savedAnswer.sourceId == sourceChange
          );
          // setAnswerIdReal(matchedAnswer?.id);
          setAnsId(matchedAnswer?.id);
        } else {
          matchedAnswer = letestAnswer.find(
            (savedAnswer) => savedAnswer.questionId == item.question.questionId
          );

          // setAnswerIdReal(matchedAnswer?.id);
          setAnsId(matchedAnswer?.id);
        }
        if (matchedAnswer) {
          if (matchedAnswer?.notApplicable) {
            setApplicable(!matchedAnswer.notApplicable);
          } else {
            setApplicable(true);
          }

          setAnsId(matchedAnswer?.id);
          setNote((prevState) => {
            // Check if matchedAnswer.note is a string
            if (typeof matchedAnswer.note === "string") {
              return [[matchedAnswer.note]]; // Wrap the string in a 2D array
            }

            // Check if matchedAnswer.note is a 2D array
            if (
              Array.isArray(matchedAnswer.note) &&
              Array.isArray(matchedAnswer.note[0])
            ) {
              return matchedAnswer.note; // Use it as is
            }

            // If matchedAnswer.note is not a string or a 2D array, return an empty 2D array
            return [[]];
          });

          setAnswer((prevState) => ({
            ...prevState,
            fromDate: matchedAnswer.fromDate,
            toDate: matchedAnswer.toDate,
            questionId: item?.questionId,
            questionTitle: item?.title,
            moduleId: item?.moduleId,
            note: (() => {
              // Check if matchedAnswer.note is a string
              if (typeof matchedAnswer.note === "string") {
                return [[matchedAnswer.note]]; // Wrap the string in a 2D array
              }

              // Check if matchedAnswer.note is a 2D array
              if (
                Array.isArray(matchedAnswer.note) &&
                Array.isArray(matchedAnswer.note[0])
              ) {
                return matchedAnswer.note; // Use it as is
              }

              // If matchedAnswer.note is not a string or a 2D array, return an empty 2D array
              return [[""]];
            })(),
          }));
        } else {
          setAnswer((prevState) => ({
            ...prevState,
            note: [[""]],
            questionId: item?.questionId,
            moduleId: item?.moduleId,
            questionType: item?.questionType,
            frequency: item?.frequency,
          }));
          setNote([[]]);
        }
      }
    } else {
      if (item && item.questionId && letestAnswer && letestAnswer.length) {
        // const matchedAnswer = savedAnswers.find(
        //   (savedAnswer) => savedAnswer.questionId == item.questionId
        // );
        let matchedAnswer;
        if (item?.frequency == "CUSTOM") {
          matchedAnswer = letestAnswer.find(
            (savedAnswer) =>
              savedAnswer.questionId == item.questionId &&
              savedAnswer.toDate == toDate &&
              savedAnswer.fromDate == fromDate &&
              savedAnswer.sourceId == sourceChange
          );
          // setAnswerIdReal(matchedAnswer?.id);
          setAnsId(matchedAnswer?.id);
        } else {
          matchedAnswer = letestAnswer.find(
            (savedAnswer) => savedAnswer.questionId == item.questionId
          );
          // setAnswerIdReal(matchedAnswer?.id);
          setAnsId(matchedAnswer?.id);
        }
        if (matchedAnswer) {
          // setAnswerIdReal(matchedAnswer?.id);

          if (matchedAnswer?.notApplicable) {
            setApplicable(!matchedAnswer.notApplicable);
          } else {
            setApplicable(true);
          }

          setAnsId(matchedAnswer?.id);
          setNote((prevState) => {
            // Check if matchedAnswer.note is a string
            if (typeof matchedAnswer.note === "string") {
              return [[matchedAnswer.note]]; // Wrap the string in a 2D array
            }

            // Check if matchedAnswer.note is a 2D array
            if (
              Array.isArray(matchedAnswer.note) &&
              Array.isArray(matchedAnswer.note[0])
            ) {
              return matchedAnswer.note; // Use it as is
            }

            // If matchedAnswer.note is not a string or a 2D array, return an empty 2D array
            return [[]];
          });

          setAnswer((prevState) => ({
            ...prevState,
            note: matchedAnswer?.note || [[]],
            questionId: item?.questionId,
            questionTitle: item?.title,

            moduleId: item?.moduleId,
            notApplicable: matchedAnswer?.notApplicable ?? false,
          }));
        } else {
          setApplicable(true);
          setAnswer((prevState) => ({
            ...prevState,
            note: [[""]],
            questionId: item?.questionId,
            moduleId: item?.moduleId,
            questionTitle: item?.title,

            questionType: item?.questionType,
            frequency: item?.frequency,
            notApplicable: matchedAnswer?.notApplicable ?? false,
          }));
          setNote([[]]);
        }
      } else {
        setAnsId(null);
      }
    }
  };
  const handleRemarkChange = (e) => {
    const newRemark = e.target.value;
    setRemark(newRemark);
  };

  useEffect(() => {
    if (letestAnswer?.length > 0 && Object.keys(letestAnswer[0]).length > 0) {
      initializeAnswer();
      if (menu === "audit") {
        let matchedAnswer = letestAnswer.find(
          (savedAnswer) => savedAnswer.questionId == item.question.questionId
        );
        if (matchedAnswer) {
          setStatus(matchedAnswer?.status);
        }
      } else {
        let matchedAnswer = letestAnswer.find(
          (savedAnswer) => savedAnswer.questionId == item.questionId
        );
        if (matchedAnswer) {
          setStatus(matchedAnswer?.status);
        }
      }
    } else {
      setStatus("Not Answered");
    }
  }, [letestAnswer, toDate, fromDate, item, financeObject]);

  useEffect(() => {
    if (assignedToDetails?.assignedToDetails?.length > 0) {
      const assignedDetail = assignedToDetails.assignedToDetails.find(
        (detail) => parseInt(detail.id, 10) === currentUserId
      );
      if (assignedDetail) {
        setSelectedValue(assignedDetail.first_name);
      }
    }
  }, [assignedToDetails, currentUserId]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  useEffect(() => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      financialYearId: financeObject,
    }));
  }, [financeObject]);

  const [status, setStatus] = useState("");

  const handleApplicableChange = (value) => {
    setApplicable(value);
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      notApplicable: !value,
    }));
  };

  const handleAccept = async () => {
    const { isSuccess, error, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
      {},
      {
        questionId: item.question.questionId,
        // answerId: answerIdReal,
        answerId: ansId,
        questionType: item.question.questionType,
        remark: remark,
        validation: "ACCEPTED",
        financialYearId: financeObject,
      },
      "POST"
    );

    if (isSuccess) {
      setAuditButton(true);
      getAuditListing();
    }

    if (error) {
      swal({
        icon: "error",
        title: data.message,
        timer: 1000,
      });
    }
  };

  const handleReject = async () => {
    if (remark) {
      const { isSuccess, error, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
        {},
        {
          questionId: item?.question?.questionId,
          answerId: ansId,
          questionType: item?.question?.questionType,
          remark: remark,
          validation: "REJECTED",
          financialYearId: financeObject,
        },
        "POST"
      );

      if (isSuccess) {
        setAuditButton(true);
        getAuditListing();
      }

      if (error) {
        swal({
          icon: "error",
          title: data.message,
          timer: 1000,
        });
      }
    } else {
      swal({
        icon: "error",
        title: "Please enter remark",
        timer: 1000,
      });
    }
  };

  const handleNoteChange = (e) => {
    const newNote = e.target.value;
    setNote([[newNote]]);
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      note: [[newNote]],
    }));
  };

  const handleSubmit = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}saveAnswerReportingQuestion`,
      {},
      {
        ...answer,
        financialYearId: financeObject,
        fromDate: fromDate,
        toDate: toDate,
      },
      "POST"
    );

    if (isSuccess) {
      getReportingAnswer()
    }
  };

  const handleApplicableCheck = (items) => {
    if (menu === "audit") {
      let matchedAnswer = letestAnswer.find(
        (savedAnswer) =>
          savedAnswer.questionId == item.questionId &&
          savedAnswer.toDate == toDate &&
          savedAnswer.fromDate == fromDate &&
          savedAnswer.sourceId == sourceChange
      );

      if (matchedAnswer?.notApplicable) {
        setApplicable(!matchedAnswer.notApplicable);
      } else {
        setApplicable(true);
      }
    } else {
      let matchedAnswer;

      if (item?.frequency == "CUSTOM") {
        matchedAnswer = letestAnswer.find(
          (savedAnswer) =>
            savedAnswer.questionId == item.questionId &&
            savedAnswer.toDate == toDate &&
            savedAnswer.fromDate == fromDate
        );
      } else {
        matchedAnswer = letestAnswer.find(
          (savedAnswer) => savedAnswer.questionId == item.questionId
        );
      }

      if (matchedAnswer?.notApplicable) {
        setApplicable(!matchedAnswer.notApplicable);
      } else {
        setApplicable(true);
      }
    }
  };

  const handleTitle = (item) => {
    setSingleItem(menu === "audit" ? item.question : item);
  };

  const handleDoubleClick = (data) => {
    if (data.trim() !== "") {
      // Check if data is not an empty string
      setShowData(data);
      setIsModalOpen(true);
    }
  };

  return (
    <div className="accordion-item my-3" key={index}>
      <h2 className="accordion-header" id={`heading${index}`}>
        <button
          className="accordion-button d-flex justify-content-between align-items-center"
          type="button"
          style={{
            backgroundColor: "#BFD7E0",
            color: "black",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0.7rem 1rem",
          }}
          onClick={() => {
            handleAccordionClick(index);
            handleTitle(item);
            handleApplicableCheck(item);
          }}
          aria-expanded={activeIndex === index}
          aria-controls={`collapse${index}`}
        >
          <div style={{ flex: "0 0 65%" }}>
            <span style={{ color: "black", marginBottom: "10px" }}>
              {index + 1}. {item.title.replace(/\b(Yes|No)\b/g, "")}
            </span>
          </div>
          <div>
            {assignedToDetails?.assignedToDetails && (
              <div style={{ color: "grey", fontSize: "12px" }}>
                {"Assigned To :- "}
                {assignedToDetails.assignedToDetails.length > 2 ? (
                  <>
                    {assignedToDetails.assignedToDetails
                      .slice(0, 2)
                      .map((detail) => detail?.first_name)
                      .filter((name) => name)
                      .join(", ")}{" "}
                    <a
                      style={{
                        background: "none",
                        border: "none",
                        textDecoration: "underline  !important",
                        underline: { textDecorationLine: "underline" },
                        cursor: "pointer",
                        fontSize: "12px",
                        zIndex: 100,
                        color: "blue",
                      }}
                      onClick={() =>
                        handleShowAssign(
                          item,
                          assignedToDetails.assignedToDetails
                        )
                      }
                    >
                      View More
                    </a>
                  </>
                ) : (
                  assignedToDetails.assignedToDetails
                    .map((detail) => detail?.first_name)
                    .filter((name) => name)
                    .join(", ")
                )}
              </div>
            )}
          </div>
          {<StatusWithTooltip status={status} />}

          <div
            style={{
              flex: "0 0 1%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span
              className="btn btn-sm btn-outline-secondary "
              style={{
                fontWeight: "bold",
                border: "1.5px solid",
                borderColor: "grey",
                padding: "0.4%",
                marginRight: menu === "audit" ? "-2rem" : "2rem",
                paddingLeft: "7%",
                paddingRight: "7%",
              }}
            >
              {activeIndex === index ? "-" : "+"}
            </span>
          </div>
        </button>
      </h2>
      <div
        id={`collapse${index}`}
        className={`accordion-collapse collapse ${
          activeIndex === index ? "show" : ""
        }`}
        aria-labelledby={`heading${index}`}
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body">
          <div
            className=""
            style={{
              background: "#E3EBED",
              height: "40px",
              width: "107%",
              marginTop: "-4%",
              marginLeft: "-4.5%",
            }}
          ></div>
          <div className="p-3 ">
            <div
              style={{
                border: "1px solid grey",
                padding: "5px 15px 20px 15px",
                borderRadius: "10px",
                marginTop: "2%",
                display: "flex",
                justifyContent: "space-between", // This will space out the child divs
                alignItems: "center", // This will vertically align the child divs in the middle
              }}
            >
              {meterList?.length === 1 &&
                meterList[0]?.subLocation?.length > 0 && (
                  <>
                    <Form.Group>
                      <Form.Label className="custom-label mx-3">
                        Select Unit
                      </Form.Label>
                      <Dropdown onSelect={handleSubLocationSelect}>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          style={{
                            backgroundColor: "#Dfebef",
                            color: "black",
                            borderColor: "white",
                            width: "100%",
                            overflow: "auto",
                          }}
                        >
                          {selectedSubLocation ||
                            meterList[0].subLocation[0].subLocation}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {meterList[0].subLocation.map((item) => (
                            <Dropdown.Item key={item.id} eventKey={item.id}>
                              {item.subLocation}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                  </>
                )}

              {meterList?.length > 1 ? (
                 <Col md={2}>
                 <Form.Group controlId="formInput10" style={styles.formGroup}>
                   <Form.Label className="custom-label">Source</Form.Label>
                   <div className="select-wrapper w-100">
                     <Dropdown  style={{ width: "100%" }}>
                       {meterList?.length === 1 ? (
                         <div
                           style={{
                             backgroundColor: "#Dfebef",
                             color: "black",
                             borderColor: "white",
                             width: "100%",
                             overflow: "auto",
                             padding: "10px",
                             borderRadius: "4px",
                           }}
                         >
                           {meterList[0]?.unitCode || meterList[0]?.location}
                         </div>
                       ) : (
                         <>
                           <Dropdown.Toggle
                             id="dropdown-basic"
                             readOnly={
                               menu === "audit" ||
                               (assignedToDetails?.assignedTo?.length > 0 &&
                                 !assignedToDetails?.assignedTo?.some(
                                   (id) => parseInt(id, 10) === currentUserId
                                 ))
                             }
                             style={{
                               backgroundColor: "#Dfebef",
                               color: "black",
                               borderColor: "white",
                               width: "100%",
                               overflow: "auto",
                             }}
                           >
                             <span style={{ marginRight: "5%" }}>
                               {sourceName || selectedSource || "Select Source"}
                             </span>
                           </Dropdown.Toggle>
                           <Dropdown.Menu>
                             {meterList?.map((item) => (
                               <div
                                 key={item.id}
                                 onMouseEnter={() => handleMouseEnter(item.id)}
                                 onMouseLeave={handleMouseLeave}
                                 style={{ position: "relative" }}
                               >
                                 <Dropdown.Item onClick={() => handleSourceSelect(item)}>
                                   {item?.unitCode || item?.location?.area}
                                   {item?.subLocation?.length > 0 && (
                                     <span style={{ marginLeft: "10px" }}>▶</span>
                                   )}
                                 </Dropdown.Item>
           
                                 {/* Submenu */}
                                 {item?.subLocation?.length > 0 && showSubMenu === item.id && (
                                   <div
                                     className="dropdown-menu show"
                                     onMouseEnter={() => setShowSubMenu(item.id)} // Keep open while hovering submenu
                                     onMouseLeave={() => setShowSubMenu(null)} // Close only when leaving submenu
                                     style={{
                                       position: "absolute",
                                       left: "100%",
                                       top: 0,
                                       backgroundColor: "white",
                                       border: "1px solid #ddd",
                                       zIndex: 1000,
                                       minWidth: "150px",
                                       boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                       borderRadius: "4px",
                                     }}
                                   >
                                     {item.subLocation.map((sub) => (
                                       <Dropdown.Item key={sub.id} onClick={() => handleSourceSelect(item, sub)}>{sub.subLocation}</Dropdown.Item>
                                     ))}
                                   </div>
                                 )}
                               </div>
                             ))}
                           </Dropdown.Menu>
                         </>
                       )}
                     </Dropdown>
                   </div>
                 </Form.Group>
               </Col>
              ) : (
                <></>
              )}
              <FrequencyComponent
                sourceData={sourceData}
                answer={answer}
                setAnswer={setAnswer}
                startingMonth={startingMonth}
                item={menu === "audit" ? item.question : item}
                matchingAuditors={
                  menu === "audit" ? item?.matchingAuditors : []
                }
                handlePeriodSelect={handlePeriodSelect}
                selectedPeriod={selectedPeriod}
                financialYear={financialYear}
                setFromDate={setFromDate}
                setToDate={setToDate}
                assignedToDetails={assignedToDetails}
                currentUserId={currentUserId}
                menu={menu}
                setSourceChange={setSourceChange}
              />
            </div>
            <div
              style={{
                border: "1px solid grey",
                padding: "5px 15px 20px 15px",
                borderRadius: "10px",
                marginTop: "2%",
              }}
            >
              <Form>
                {/* First Row with 5 input texts */}
                <Row className="mb-3">
                  <Col className="col-20">
                    <Form.Group controlId="formInput1">
                      <Form.Label className="custom-label">
                        Assigned By
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        style={{ backgroundColor: "#Dfebef" }}
                        value={
                          assignedToDetails?.assignedByDetails?.[0]
                            ?.first_name || ""
                        }
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-20">
                    <Form.Group controlId="formInput2">
                      <Form.Label className="custom-label">
                        Assigned To
                      </Form.Label>
                      <Form.Control
                        as="select"
                        className="form-control"
                        style={{ backgroundColor: "#Dfebef" }}
                        value={selectedValue}
                        onChange={handleChange}
                        readOnly
                      >
                        {assignedToDetails?.assignedToDetails?.map(
                          (detail, index) => {
                            let sourceIdsArray = Array.isArray(
                              detail?.source_ids
                            )
                              ? detail.source_ids
                              : typeof detail?.source_ids === "string"
                              ? JSON.parse(detail.source_ids)
                              : [];

                            if (
                              sourceIdsArray.some((id) => id === sourceChange)
                            ) {
                              return (
                                <option key={index} value={detail?.first_name}>
                                  {detail?.first_name}
                                </option>
                              );
                            }
                            return null; // Return null if the condition is not met
                          }
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="col-20">
                    <Form.Group controlId="formInput3">
                      <Form.Label className="custom-label">
                        Assign Date
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        style={{ backgroundColor: "#Dfebef" }}
                        value={
                          assignedToDetails?.createdAt
                            ? new Date(
                                assignedToDetails.createdAt
                              ).toLocaleDateString("en-GB")
                            : ""
                        }
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-20">
                    <Form.Group controlId="formInput4">
                      <Form.Label className="custom-label">Due Date</Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        style={{ backgroundColor: "#Dfebef" }}
                        value={
                          assignedToDetails?.dueDate
                            ? new Date(
                                assignedToDetails.dueDate
                              ).toLocaleDateString("en-GB")
                            : ""
                        }
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-20">
                    <Form.Group controlId="formInput5">
                      <Form.Label className="custom-label">
                        Answered Date
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        style={{ backgroundColor: "#Dfebef" }}
                        value={
                          updatedAt
                            ? new Date(updatedAt).toLocaleDateString("en-GB")
                            : ""
                        }
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* Second Row with 4 input texts */}

                {auditorRemark &&
                  ansId &&
                  auditorRemark.map((user) => {
                    return (
                      <Row className="mb-3" key={user.id}>
                        <Col>
                          <Form.Group controlId={`formInput6-${user.id}`}>
                            <Form.Label className="custom-label">
                              Audited By
                            </Form.Label>
                            <Form.Control
                              type="text"
                              style={{ backgroundColor: "#Dfebef" }}
                              value={user?.firstName || ""}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId={`formInput7-${user.id}`}>
                            <Form.Label className="custom-label">
                              Audited Date
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              style={{ backgroundColor: "#Dfebef" }}
                              value={
                                user
                                  ? new Date(
                                      user?.auditedDate
                                    ).toLocaleDateString()
                                  : ""
                              }
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId={`formInput8-${user.id}`}>
                            <Form.Label className="custom-label">
                              Question Status
                            </Form.Label>
                            <Form.Control
                              type="text"
                              style={{ backgroundColor: "#Dfebef" }}
                              value={user?.status || "Accepted"}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId={`formInput9-${user.id}`}>
                            <Form.Label className="custom-label">
                              Auditor Remark
                            </Form.Label>
                            <Form.Control
                              type="text"
                              style={{ backgroundColor: "#Dfebef" }}
                              value={user?.remark}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    );
                  })}
              </Form>
            </div>
            {(menu === "audit"
              ? item?.question?.applicableCheck
              : item?.applicableCheck) === 1 && (
              <div className="checkkbox">
                <div
                  className="checkkbox d-flex mt-4"
                  style={{ marginLeft: "5px" }}
                >
                  <div className="form-check" style={{ fontSize: "1rem" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="applicableCheckbox"
                      checked={applicable}
                      onChange={() => {
                        if (
                          assignedToDetails?.assignedTo?.length > 0 &&
                          assignedToDetails?.assignedTo?.some(
                            (id) => parseInt(id, 10) === currentUserId
                          )
                        ) {
                          handleApplicableChange(true); // Set applicable to true
                        }
                      }} // Set applicable to true
                      style={{ transform: "scale(1.5)" }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="applicableCheckbox"
                    >
                      Applicable
                    </label>
                  </div>
                  <div
                    className="form-check"
                    style={{ marginLeft: "1rem", fontSize: "1rem" }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="notApplicableCheckbox"
                      checked={!applicable}
                      onChange={() => {
                        if (
                          assignedToDetails?.assignedTo?.length > 0 &&
                          assignedToDetails?.assignedTo?.some(
                            (id) => parseInt(id, 10) === currentUserId
                          )
                        ) {
                          handleApplicableChange(false); // Set applicable to true
                        }
                      }} // Set applicable to false
                      style={{ transform: "scale(1.5)" }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="notApplicableCheckbox"
                    >
                      Not Applicable
                    </label>
                  </div>
                </div>
                {!applicable ? (
                  <div>
                    <Row>
                      <Col md={12}>
                        <Form.Group controlId="formInput12">
                          <Form.Label className="custom-label">Note</Form.Label>
                          <Form.Control
                            style={{ backgroundColor: "#Dfebef" }}
                            type="text"
                            as="textarea"
                            value={note[0][0]}
                            onChange={handleNoteChange}
                            onDoubleClick={() => handleDoubleClick(note[0][0])}
                            readOnly={
                              menu === "audit" ||
                              (assignedToDetails?.assignedTo?.length > 0 &&
                                !assignedToDetails?.assignedTo?.some(
                                  (id) => parseInt(id, 10) === currentUserId
                                ))
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Col md={8}>
                      <Form.Group controlId="formInput12">
                        <Form.Label className="custom-label">Remark</Form.Label>
                        <Form.Control
                          style={{ backgroundColor: "#Dfebef" }}
                          type="text"
                          as="textarea"
                          onDoubleClick={() => handleDoubleClick(remark)}
                          value={remark}
                          onChange={handleRemarkChange}
                        />
                      </Form.Group>
                    </Col>

                    <div>
                      {menu === "audit" ? (
                        <>
                          {item &&
                            item?.matchingAuditors.find(
                              (d) =>
                                //  d.answerId == answerIdReal
                                d.answerId == ansId
                            )?.auditerId == currentUserId &&
                            // answerIdReal
                            ansId && (
                              <>
                                {(item &&
                                  item?.matchingAuditors.find(
                                    (d) =>
                                      d.answerId ==
                                      // answerIdReal
                                      ansId
                                  )?.remark == null) ||
                                item?.matchingAuditors
                                  .find(
                                    (d) =>
                                      d.answerId ==
                                      //  answerIdReal
                                      ansId
                                  )
                                  ?.remark?.every(
                                    (remark) => remark.id != currentUserId
                                  ) ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <button
                                        className="btn"
                                        onClick={handleAccept}
                                        style={{
                                          borderColor: "white",
                                          backgroundColor: "#3F88A5",
                                          color: "white",
                                          padding: "1%",
                                          paddingLeft: "5%",
                                          paddingRight: "5%",
                                        }}
                                      >
                                        Accept
                                      </button>
                                      <button
                                        className="btn"
                                        onClick={handleReject}
                                        style={{
                                          borderColor: "white",
                                          backgroundColor: "#3F88A5",
                                          color: "white",
                                          padding: "1%",
                                          paddingLeft: "5%",
                                          paddingRight: "5%",
                                        }}
                                      >
                                        Reject
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                        </>
                      ) : (
                        assignedToDetails?.assignedTo?.length > 0 &&
                        assignedToDetails?.assignedTo?.some(
                          (id) => parseInt(id, 10) === currentUserId
                        ) && (
                          <button
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            disabled={
                              item?.frequency === "CUSTOM"
                                ? !fromDate
                                  ? true
                                  : false
                                : false
                            }
                            style={{
                              borderColor: "white",
                              backgroundColor: "#3F88A5",
                              paddingLeft: "5%",
                              paddingRight: "5%",
                              marginTop: "5%",
                            }}
                          >
                            Submit
                          </button>
                        )
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}

            {applicable && (
              <div
                style={{
                  border: "1px solid grey",
                  padding: "5px 15px 20px 15px",
                  borderRadius: "10px",
                  marginTop: "2%",
                }}
              >
                {item?.questionType === "qualitative" && (
                  <QualitativeComponent
                    sourceData={sourceData}
                    selectedPeriod={selectedPeriod}
                    apiData={apiData}
                    getAuditListing={getAuditListing}
                    // getReportingQuestions={getReportingQuestions}
                    questionType={item.questionType}
                    currentUserId={currentUserId}
                    dueDate={assignedToDetails?.dueDate}
                    auditItem={item}
                    setAnsId={setAnsId}
                    assignedToDetails={assignedToDetails}
                    menu={menu}
                    savedAnswers={savedAnswers}
                    setUpdatedAt={setUpdatedAt}
                    item={menu === "audit" ? item.question : item}
                    {...(menu === "audit" ? { answerId: item?.answer } : {})}
                    answer={answer}
                    auditorId={auditorId}
                    setAnswer={setAnswer}
                    financeObject={financeObject}
                    title={item.title}
                    toDate={toDate}
                    fromDate={fromDate}
                    sourceChange={sourceChange}
                    singleItem={singleItem}
                  />
                )}
                {item?.questionType === "quantitative" && (
                  <QualitativeComponent
                    sourceData={sourceData}
                    // getReportingQuestions={getReportingQuestions}
                    questionType={item.questionType}
                    apiData={apiData}
                    setUpdatedAt={setUpdatedAt}
                    assignedToDetails={assignedToDetails}
                    currentUserId={currentUserId}
                    dueDate={assignedToDetails?.dueDate}
                    auditItem={item}
                    selectedPeriod={selectedPeriod}
                    menu={menu}
                    getAuditListing={getAuditListing}
                    auditorId={auditorId}
                    setAnsId={setAnsId}
                    answer={answer}
                    {...(menu === "audit" ? { answerId: item?.answer } : {})}
                    savedAnswers={savedAnswers}
                    setAnswer={setAnswer}
                    item={menu === "audit" ? item.question : item}
                    financeObject={financeObject}
                    title={item.title}
                    toDate={toDate}
                    fromDate={fromDate}
                    sourceChange={sourceChange}
                    singleItem={singleItem}
                    selectedSubLocationId={selectedSubLocationId}
                  />
                )}
                {item?.questionType === "tabular_question" && (
                  <TabularComponent
                    sourceData={sourceData}
                    getAuditListing={getAuditListing}
                    assignedToDetails={assignedToDetails}
                    currentUserId={currentUserId}
                    menu={menu}
                    setUpdatedAt={setUpdatedAt}
                    // getReportingQuestions={getReportingQuestions}
                    auditorId={auditorId}
                    dueDate={assignedToDetails?.dueDate}
                    setAnsId={setAnsId}
                    answer={answer}
                    selectedPeriod={selectedPeriod}
                    savedAnswers={savedAnswers}
                    auditItem={item}
                    title={item.title}
                    {...(menu === "audit" ? { answerId: item?.answer } : {})}
                    setAnswer={setAnswer}
                    item={menu === "audit" ? item.question : singleItem}
                    financeObject={financeObject}
                    toDate={toDate}
                    fromDate={fromDate}
                    sourceChange={sourceChange}
                    apiData={apiData}
                    singleItem={singleItem}
                    selectedSubLocationId={selectedSubLocationId}
                  />
                )}
                {item?.questionType === "yes_no" && (
                  <YesNoComponent
                    sourceData={sourceData}
                    // getReportingQuestions={getReportingQuestions}
                    assignedToDetails={assignedToDetails}
                    currentUserId={currentUserId}
                    auditItem={item}
                    setAnsId={setAnsId}
                    {...(menu === "audit" ? { answerId: item?.answer } : {})}
                    answer={answer}
                    setUpdatedAt={setUpdatedAt}
                    getAuditListing={getAuditListing}
                    auditorId={auditorId}
                    menu={menu}
                    dueDate={assignedToDetails?.dueDate}
                    selectedPeriod={selectedPeriod}
                    savedAnswers={savedAnswers}
                    setAnswer={setAnswer}
                    item={menu === "audit" ? item.question : singleItem}
                    financeObject={financeObject}
                    sourceChange={sourceChange}
                    title={item.title}
                    toDate={toDate}
                    fromDate={fromDate}
                    singleItem={singleItem}
                    selectedSubLocationId={selectedSubLocationId}
                  />
                )}
                {singleItem && item?.questionType === "quantitative_trends" && (
                  <TrendsComponent
                    sourceData={sourceData}
                    assignedToDetails={assignedToDetails}
                    answer={answer}
                    apiData={apiData}
                    // getReportingQuestions={getReportingQuestions}
                    selectedPeriod={selectedPeriod}
                    currentUserId={currentUserId}
                    setUpdatedAt={setUpdatedAt}
                    auditItem={item}
                    title={item.title}
                    setAnsId={setAnsId}
                    auditorId={auditorId}
                    getAuditListing={getAuditListing}
                    menu={menu}
                    dueDate={assignedToDetails?.dueDate}
                    financeObject={financeObject}
                    savedAnswers={savedAnswers}
                    setAnswer={setAnswer}
                    item={menu === "audit" ? item.question : singleItem}
                    toDate={toDate}
                    fromDate={fromDate}
                    sourceChange={sourceChange}
                    unit={unit}
                    singleItem={singleItem}
                    selectedSubLocationId={selectedSubLocationId}
                    subMenuId={subMenuId}
                  />
                )}
              </div>
            )}
          </div>
          <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>{showData}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={isAssignModalOpen}
            onHide={() => setAssignIsModalOpen(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>User Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {details.map((item, index) => (
                    <tr key={index}>
                      <td>{item.first_name}</td>
                      <td>Answered</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setAssignIsModalOpen(false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default MainAccordComponent;
